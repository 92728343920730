@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap");

.App {
  text-align: center;
}
body {
  overflow: hidden !important;
  font-family: "Montserrat", sans-serif !important;
}
h1 {
  font-weight: 300;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banneder {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.247);
  position: fixed;
  z-index: 200000;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannedcard {
  width: 90vw;
  max-width: 500px;
  height: fit-content;
  background-color: rgba(252, 252, 252, 0.596);
  backdrop-filter: blur(15px);
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
}
.nameinps {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  justify-content: space-between;
}
.nameinp > .MuiInput-underline,
.emailinp > .MuiInput-underline {
  width: 100% !important;
  min-width: 10px !important;
  max-width: 100% !important;
}
.nameinp {
  width: 48% !important;
  min-width: 10px !important;
  max-width: 48% !important;
}
.emailinp {
  width: 100% !important;
  min-width: 10px !important;
  max-width: 100% !important;
}
.ppcheckbox {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.pptext {
  text-align: left;
  font-size: 11px;
  color: rgb(85, 85, 85);
}

.pptext > a {
  color: rgb(0, 0, 0);
}
