.logincont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.logincont > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logincont > form > * {
  margin-bottom: 20px !important;
}
