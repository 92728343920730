@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.productPageAction {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.productPageActionBuy {
  padding-left: 10px !important;
}

.App {
  text-align: center;
}
body {
  overflow: hidden !important;
  font-family: "Montserrat", sans-serif !important;
}
h1 {
  font-weight: 300;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banneder {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.247);
  position: fixed;
  z-index: 200000;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannedcard {
  width: 90vw;
  max-width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgba(252, 252, 252, 0.596);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
}
.nameinps {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  justify-content: space-between;
}
.nameinp > .MuiInput-underline,
.emailinp > .MuiInput-underline {
  width: 100% !important;
  min-width: 10px !important;
  max-width: 100% !important;
}
.nameinp {
  width: 48% !important;
  min-width: 10px !important;
  max-width: 48% !important;
}
.emailinp {
  width: 100% !important;
  min-width: 10px !important;
  max-width: 100% !important;
}
.ppcheckbox {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.pptext {
  text-align: left;
  font-size: 11px;
  color: rgb(85, 85, 85);
}

.pptext > a {
  color: rgb(0, 0, 0);
}

.logincont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.logincont > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logincont > form > * {
  margin-bottom: 20px !important;
}

.accesscont {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.accesscardcont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  border-radius: 20px;
  width: 90vw;
  background-color: white;
}

.accesscard {
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  transition: all 0.3s;
  min-width: 300px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.accesscard:hover {
  box-shadow: 2px 2px 5px rgba(0, 17, 0, 0.212);
}

.designs{
    display: flex;
    width: 100%;
    height: 90%;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1;
    margin-top: 54px;
    overflow-y: auto;

}
.designs_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:10px 30px;
    align-items: center;
}
.designformcont{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.designformcont>*{
    margin-top:10px !important;
}

#myfile, #myfileguide,#opmyfile,#mainpic,.myfilecc {
    display: none;
}
.uploadbtn{
    height: 500px;
    width: 100vw;
    border-radius: 0% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #d5d5d5;
    transition: all 0.1s linear;
    cursor: pointer;
}
.MuiDialog-paper{
    overflow-x: hidden !important;
}
.multiimageuploader{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    padding: 10px 50px;
}
.mainpic{
    height: 400px;
    width: 300px;
    background-color: transparent !important;
    border-radius: 30px !important;
}

.designs_body{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    
}
.eachdegincont{
    width: 200px;
    min-height: 300px;
    margin:10px 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 0 20px;
}
.productsbtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.productsbtn>*{
    width: 50%;
}
.productsleft{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.multiimg{
    margin: 20px;
    height: 200px;
    width: 150px;
    border-radius: 30px !important;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
}
.guidemi{
    margin: 20px;
    height: 150px;
    width: 150px;
    border-radius: 30px !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.multiimgnew{
    margin: 20px;
    height: 200px;
    width: 150px;
    border-radius: 30px !important;
    background-color: transparent !important;
    border:dashed 2px gray;
}
.uploadbtn:hover{
    box-shadow: 4px 4px 8px -2px rgb(0 0 0 / 20%), 4px 8px 10px 0px rgb(0 0 0 / 14%), 4px 1px 20px 0px rgb(0 0 0 / 12%);
}
.inputspr{
    width: 210px !important;
}
.uploadicon{
    font-size: 50px !important;
}
.multiimgntitle {
    width: 200px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.optitle>.MuiInput-underline{
    width:150px !important;
}
.producttext {
  opacity: 0;
  transition: all 0.4s;
  margin-top: calc(32vw - 100vh);
  margin-left: 22.5vw;
  width: 25vw;
}
.slick-center > div > div > .producttext {
  opacity: 1;
}
.productslidecont {
  height: 100vh;
  outline: none !important;
}
.primg {
  filter: blur(0.3rem);
  transition: all 0.4s;
  width: 22.5vw;
  height: 30vw;
  margin-left: -1vw;
  margin-right: -1vw;
  opacity: 0.6;
  margin-top: -20px;
  z-index: 500;
  position: relative;
}
.learnmorebtn {
  padding: 5px 15px !important;
  color: white;
  background-color: #272624;
  cursor: pointer !important;
  border-radius: 200px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
  position: relative;
  z-index: 9999;
}
.tagnavE {
  cursor: pointer;
  font-size: 1vw;
  transition: all 0.2s;
}
.tagnavE:hover,
.tagnavEhighlight {
  font-size: 1.1vw;
  font-weight: 600;
}
.tagnavmobile {
  display: none;
}
@media (max-width: 1024px) {
  .primg {
    width: 33.3vw;
    height: 44.4vw;
  }

  .overlays {
    bottom: calc(100vh - 45vw) !important;
  }
}
@media only screen and (max-width: 600px) {
  .slick-center > div > div > .primg {
    margin-left: 0px !important;
  }
  .tagnav {
    display: none;
  }
  .tagnavmobile {
    display: unset;
    position: absolute;
    top: calc(60vh + 110px);
    width: 100%;
    left: 0;
    text-align: center;
  }
}
.prlikebtn {
  margin-left: 0px;
  margin-right: 0px;
  opacity: 0;
  margin-bottom: -50px !important;
  margin-left: 70% !important;
  z-index: 1001;
  position: relative;
  transition: all 0.4s !important;
  pointer-events: none;
}
.slick-center > div > div > .prlikebtn {
  opacity: 1;
  pointer-events: unset;
}
.slick-center > div > div > .primg {
  filter: blur(0);
  width: 100%;
  width: 66.6vh;
  height: 100vh;
  margin-left: -80px !important;
  margin-right: 20px !important;
  opacity: 1;
  z-index: 499;
  position: relative;
}
.producttext > * {
  text-align: left;
  margin-bottom: -2px;
  z-index: 502;
  font-size: medium;
}
.navbtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
  align-items: center;
  top: 40%;
  position: absolute;
  z-index: 1000;
  margin-left: 5vw;
}
.overlays {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
  bottom: calc(100vh - 31vw);
  position: absolute;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */ /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  z-index: 200 !important;
  filter: blur(8px);
}
.overlays > * {
  height: 20vw;
  width: 32vw;
}
.tagnav {
  background-color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 20px;
}
.btmnav {
  width: 100vw;
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.favnav {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.favnav > * {
  margin-left: 10px;
}

.iconav {
  cursor: pointer !important;
}
@media only screen and (max-width: 1000px) {
  .producttext {
    margin-top: calc(45vw - 100vh);
    margin-left: 32.5vw;
  }
}
@media only screen and (max-width: 600px) {
  .producttitle {
    font-size: medium;
    margin-top: 25px;
  }
  .primg {
    width: 30vw;
    height: 45vw;
    margin-left: -3vw;
    margin-right: -3vw;
  }
  .slick-center > div > div > .primg {
    filter: blur(0);
    width: 100%;
    width: 40vh;
    height: 60vh;
    margin-left: -9vw !important;
    margin-right: 0px !important;
    opacity: 1;
    z-index: 499;
    position: relative;
  }
  .productslidecont {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .producttext {
    margin-top: 0px;
    margin-left: -30vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90vw !important;
  }
  .producttext > * {
    text-align: center;
    font-size: medium;
  }
  .overlays {
    display: none;
  }
  .faveitem {
    width: 33vw !important;
    height: 54vw !important;
  }
  .bannerimg {
    width: 180%;
  }
  iframe {
    height: 47.25vw;
  }
  .tagnavE {
    font-size: 10px;
  }
  .tagnavE:hover,
  .tagnavEhighlight {
    font-size: 11.5px;
    font-weight: 600;
  }
}
.btmnav,
.navbtns {
  pointer-events: none;
}
.btmnav > div,
.navbtns > * {
  pointer-events: auto;
}

.prcont {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 20px 0;
  justify-content: flex-start;
  align-items: center;
}

.prbod {
  align-items: center;
  display: flex;
  width: 100vw;
  flex-direction: column;
}
.prtitle {
  text-align: center;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.prbar {
  width: 70% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.prytcont {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

iframe {
  width: 103vw;
  height: 47.25vw;
  margin-top: -60px;
  position: relative;
  z-index: 0;
  left: -2vw;
}
.opcont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.borderoverlay {
  width: 100vw;
  height: 60px !important;
  background-color: white;
  z-index: 200;
  position: relative;
}

.seemore {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mdialogheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
}
.seemore > p {
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 15px !important;
}
.alldialogcont {
  width: 40vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.backbtnproduct {
  display: flex;
  flex-direction: row;
  background-color: black;
  color: white !important;
  border-radius: 500px;
  padding: 5px 10px 5px 5px !important;
  cursor: pointer;
  align-items: center;
  font-size: 10px !important;
}
.backtext {
  margin: 0 !important;
}

.favicondetpr {
  margin-bottom: -70px !important;
  margin-left: 14vw !important;
}
@media only screen and (max-width: 600px) {
  .favicondetpr {
    margin-bottom: calc(-5vw - 50px) !important;
    margin-left: calc(15vw + 50px) !important;
  }
  .prtitle {
    width: 90%;
  }
  .prbar {
    width: 80% !important;
  }
  .opcont {
    width: 92vw !important;
  }
  .multiimg {
    width: 35vw !important;
    height: 47vw !important;
  }
  iframe {
    margin-top: -30px;
    width: 150vw;
    height: 71.25vw;
    margin-left: -25vw;
  }

  .borderoverlay {
    height: 30px !important;
  }
}
@media print {
  .hideinprint {
    display: none;
  }
  *::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
    background-color: white !important;
  }
  .praminimg {
    width: 90% !important;
  }
}
.productPageActionBuy {
  padding-left: 10px !important;
}
.productPageAction > * {
  margin-left: 10px;
}

.tutorialCont {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2000;
}

.pcslide {
  width: 70vw !important;
  height: auto;
  border-radius: 20px;
}
.mslide {
  width: 100% !important;
  height: auto;
  border-radius: 20px;
}

.tutorialmodal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  margin: 0 10vw;
  padding: 10px 20px 20px 20px;
  border-radius: 30px;
}
.tutorialbutton {
  margin: 5px 10px 0 10px !important;
}
@media only screen and (max-width: 600px) {
  .tutorialmodal {
    margin: 0 5vw;
  }
}

.favcont{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
    height: 100vh;
    overflow-x: hidden;
    align-items: center;

}
.favheader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    background-color: white;
    width: 70vw;
}
.favbody{
    width: 70vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    transition: all 0.5s;
}
.faveitem{
    cursor: pointer;
    margin:1vw;
    background-position: center !important;
    background-size: cover !important;
    width: 15vw;
    height: 20vw;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: whitesmoke !important;
    z-index: 1;
}

.favprs{
    position: relative;
}
.favbtninfavitem{
    position: absolute !important;
    top: 1.5vw;
    right: 1.5vw;
    z-index: 2;
}

