.favcont{
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    height: 100vh;
    overflow-x: hidden;
    align-items: center;

}
.favheader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    background-color: white;
    width: 70vw;
}
.favbody{
    width: 70vw;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    transition: all 0.5s;
}
.faveitem{
    cursor: pointer;
    margin:1vw;
    background-position: center !important;
    background-size: cover !important;
    width: 15vw;
    height: 20vw;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: whitesmoke !important;
    z-index: 1;
}

.favprs{
    position: relative;
}
.favbtninfavitem{
    position: absolute !important;
    top: 1.5vw;
    right: 1.5vw;
    z-index: 2;
}
