.accesscont {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.accesscardcont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  border-radius: 20px;
  width: 90vw;
  background-color: white;
}

.accesscard {
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  transition: all 0.3s;
  min-width: 300px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.accesscard:hover {
  box-shadow: 2px 2px 5px rgba(0, 17, 0, 0.212);
}
