.prcont {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 20px 0;
  justify-content: flex-start;
  align-items: center;
}

.prbod {
  align-items: center;
  display: flex;
  width: 100vw;
  flex-direction: column;
}
.prtitle {
  text-align: center;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.prbar {
  width: 70% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.prytcont {
  width: 100%;
  height: fit-content;
}

iframe {
  width: 103vw;
  height: 47.25vw;
  margin-top: -60px;
  position: relative;
  z-index: 0;
  left: -2vw;
}
.opcont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.borderoverlay {
  width: 100vw;
  height: 60px !important;
  background-color: white;
  z-index: 200;
  position: relative;
}

.seemore {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mdialogheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
}
.seemore > p {
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 15px !important;
}
.alldialogcont {
  width: 40vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.backbtnproduct {
  display: flex;
  flex-direction: row;
  background-color: black;
  color: white !important;
  border-radius: 500px;
  padding: 5px 10px 5px 5px !important;
  cursor: pointer;
  align-items: center;
  font-size: 10px !important;
}
.backtext {
  margin: 0 !important;
}

.favicondetpr {
  margin-bottom: -70px !important;
  margin-left: 14vw !important;
}
@media only screen and (max-width: 600px) {
  .favicondetpr {
    margin-bottom: calc(-5vw - 50px) !important;
    margin-left: calc(15vw + 50px) !important;
  }
  .prtitle {
    width: 90%;
  }
  .prbar {
    width: 80% !important;
  }
  .opcont {
    width: 92vw !important;
  }
  .multiimg {
    width: 35vw !important;
    height: 47vw !important;
  }
  iframe {
    margin-top: -30px;
    width: 150vw;
    height: 71.25vw;
    margin-left: -25vw;
  }

  .borderoverlay {
    height: 30px !important;
  }
}
@media print {
  .hideinprint {
    display: none;
  }
  *::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
    background-color: white !important;
  }
  .praminimg {
    width: 90% !important;
  }
}
.productPageActionBuy {
  padding-left: 10px !important;
}
.productPageAction > * {
  margin-left: 10px;
}
