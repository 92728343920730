.tutorialCont {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2000;
}

.pcslide {
  width: 70vw !important;
  height: auto;
  border-radius: 20px;
}
.mslide {
  width: 100% !important;
  height: auto;
  border-radius: 20px;
}

.tutorialmodal {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: white;
  margin: 0 10vw;
  padding: 10px 20px 20px 20px;
  border-radius: 30px;
}
.tutorialbutton {
  margin: 5px 10px 0 10px !important;
}
@media only screen and (max-width: 600px) {
  .tutorialmodal {
    margin: 0 5vw;
  }
}
