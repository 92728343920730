.designs{
    display: flex;
    width: 100%;
    height: 90%;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    margin-top: 54px;
    overflow-y: auto;

}
.designs_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:10px 30px;
    align-items: center;
}
.designformcont{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.designformcont>*{
    margin-top:10px !important;
}

#myfile, #myfileguide,#opmyfile,#mainpic,.myfilecc {
    display: none;
}
.uploadbtn{
    height: 500px;
    width: 100vw;
    border-radius: 0% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #d5d5d5;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    cursor: pointer;
}
.MuiDialog-paper{
    overflow-x: hidden !important;
}
.multiimageuploader{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    padding: 10px 50px;
}
.mainpic{
    height: 400px;
    width: 300px;
    background-color: transparent !important;
    border-radius: 30px !important;
}

.designs_body{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    
}
.eachdegincont{
    width: 200px;
    min-height: 300px;
    margin:10px 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 0 20px;
}
.productsbtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.productsbtn>*{
    width: 50%;
}
.productsleft{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.multiimg{
    margin: 20px;
    height: 200px;
    width: 150px;
    border-radius: 30px !important;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
}
.guidemi{
    margin: 20px;
    height: 150px;
    width: 150px;
    border-radius: 30px !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.multiimgnew{
    margin: 20px;
    height: 200px;
    width: 150px;
    border-radius: 30px !important;
    background-color: transparent !important;
    border:dashed 2px gray;
}
.uploadbtn:hover{
    box-shadow: 4px 4px 8px -2px rgb(0 0 0 / 20%), 4px 8px 10px 0px rgb(0 0 0 / 14%), 4px 1px 20px 0px rgb(0 0 0 / 12%);
}
.inputspr{
    width: 210px !important;
}
.uploadicon{
    font-size: 50px !important;
}
.multiimgntitle {
    width: 200px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.optitle>.MuiInput-underline{
    width:150px !important;
}