.producttext {
  opacity: 0;
  transition: all 0.4s;
  margin-top: calc(32vw - 100vh);
  margin-left: 22.5vw;
  width: 25vw;
}
.slick-center > div > div > .producttext {
  opacity: 1;
}
.productslidecont {
  height: 100vh;
  outline: none !important;
}
.primg {
  filter: blur(0.3rem);
  transition: all 0.4s;
  width: 22.5vw;
  height: 30vw;
  margin-left: -1vw;
  margin-right: -1vw;
  opacity: 0.6;
  margin-top: -20px;
  z-index: 500;
  position: relative;
}
.learnmorebtn {
  padding: 5px 15px !important;
  color: white;
  background-color: #272624;
  cursor: pointer !important;
  border-radius: 200px;
  width: fit-content;
  margin-top: 10px;
  position: relative;
  z-index: 9999;
}
.tagnavE {
  cursor: pointer;
  font-size: 1vw;
  transition: all 0.2s;
}
.tagnavE:hover,
.tagnavEhighlight {
  font-size: 1.1vw;
  font-weight: 600;
}
.tagnavmobile {
  display: none;
}
@media (max-width: 1024px) {
  .primg {
    width: 33.3vw;
    height: 44.4vw;
  }

  .overlays {
    bottom: calc(100vh - 45vw) !important;
  }
}
@media only screen and (max-width: 600px) {
  .slick-center > div > div > .primg {
    margin-left: 0px !important;
  }
  .tagnav {
    display: none;
  }
  .tagnavmobile {
    display: unset;
    position: absolute;
    top: calc(60vh + 110px);
    width: 100%;
    left: 0;
    text-align: center;
  }
}
.prlikebtn {
  margin-left: 0px;
  margin-right: 0px;
  opacity: 0;
  margin-bottom: -50px !important;
  margin-left: 70% !important;
  z-index: 1001;
  position: relative;
  transition: all 0.4s !important;
  pointer-events: none;
}
.slick-center > div > div > .prlikebtn {
  opacity: 1;
  pointer-events: unset;
}
.slick-center > div > div > .primg {
  filter: blur(0);
  width: 100%;
  width: 66.6vh;
  height: 100vh;
  margin-left: -80px !important;
  margin-right: 20px !important;
  opacity: 1;
  z-index: 499;
  position: relative;
}
.producttext > * {
  text-align: left;
  margin-bottom: -2px;
  z-index: 502;
  font-size: medium;
}
.navbtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
  align-items: center;
  top: 40%;
  position: absolute;
  z-index: 1000;
  margin-left: 5vw;
}
.overlays {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
  bottom: calc(100vh - 31vw);
  position: absolute;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  z-index: 200 !important;
  filter: blur(8px);
}
.overlays > * {
  height: 20vw;
  width: 32vw;
}
.tagnav {
  background-color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 20px;
}
.btmnav {
  width: 100vw;
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.favnav {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.favnav > * {
  margin-left: 10px;
}

.iconav {
  cursor: pointer !important;
}
@media only screen and (max-width: 1000px) {
  .producttext {
    margin-top: calc(45vw - 100vh);
    margin-left: 32.5vw;
  }
}
@media only screen and (max-width: 600px) {
  .producttitle {
    font-size: medium;
    margin-top: 25px;
  }
  .primg {
    width: 30vw;
    height: 45vw;
    margin-left: -3vw;
    margin-right: -3vw;
  }
  .slick-center > div > div > .primg {
    filter: blur(0);
    width: 100%;
    width: 40vh;
    height: 60vh;
    margin-left: -9vw !important;
    margin-right: 0px !important;
    opacity: 1;
    z-index: 499;
    position: relative;
  }
  .productslidecont {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .producttext {
    margin-top: 0px;
    margin-left: -30vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90vw !important;
  }
  .producttext > * {
    text-align: center;
    font-size: medium;
  }
  .overlays {
    display: none;
  }
  .faveitem {
    width: 33vw !important;
    height: 54vw !important;
  }
  .bannerimg {
    width: 180%;
  }
  iframe {
    height: 47.25vw;
  }
  .tagnavE {
    font-size: 10px;
  }
  .tagnavE:hover,
  .tagnavEhighlight {
    font-size: 11.5px;
    font-weight: 600;
  }
}
.btmnav,
.navbtns {
  pointer-events: none;
}
.btmnav > div,
.navbtns > * {
  pointer-events: auto;
}
